import React, { useEffect, useRef } from 'react';
import { useAdsenseInit } from '../utils/adsense';

interface AdUnitProps {
  slot: string;
  format?: 'auto' | 'fluid' | 'rectangle' | 'vertical' | 'horizontal';
  style?: React.CSSProperties;
  className?: string;
}

const AdUnit: React.FC<AdUnitProps> = ({ slot, format = 'auto', style, className = '' }) => {
  const adRef = useRef<HTMLDivElement>(null);
  const initialized = useRef(false);
  useAdsenseInit();

  useEffect(() => {
    // Only initialize if consent is given and ad hasn't been initialized yet
    if (adRef.current && !initialized.current && window.adsbygoogle && localStorage.getItem('cookieConsent') === 'true') {
      try {
        // Ensure the ad container has a minimum width
        if (adRef.current.offsetWidth > 0) {
          window.adsbygoogle.push({});
          initialized.current = true;
        }
      } catch (err) {
        console.error('AdSense error:', err);
      }
    }
  }, []);

  // Don't render if no consent
  if (localStorage.getItem('cookieConsent') !== 'true') {
    return null;
  }

  const formatStyles: { [key: string]: React.CSSProperties } = {
    horizontal: { minHeight: '90px', width: '100%', maxWidth: '970px' },
    vertical: { minHeight: '600px', width: '100%', maxWidth: '300px' },
    rectangle: { minHeight: '250px', width: '100%', maxWidth: '300px' },
    auto: { minHeight: '100px', width: '100%' },
    fluid: { minHeight: '100px', width: '100%' }
  };

  return (
    <div className={`ad-container ${className}`} ref={adRef}>
      <ins
        className="adsbygoogle"
        style={{
          display: 'block',
          textAlign: 'center',
          ...formatStyles[format],
          ...style,
        }}
        data-ad-client="ca-pub-6423086412262403"
        data-ad-slot={slot}
        data-ad-format={format}
        data-full-width-responsive="true"
      />
    </div>
  );
};

export default AdUnit;