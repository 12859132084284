import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import AdLayout from './components/AdLayout';
import PrivacyBanner from './components/PrivacyBanner';

// Import pages
import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Contact from './pages/Contact';
import Search from './pages/Search';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Disclaimer from './pages/Disclaimer';
import TermsOfService from './pages/TermsOfService';
import Sitemap from './pages/Sitemap';
import EmojiDetail from './pages/EmojiDetail';
import TextToEmoji from './pages/TextToEmoji';
import TextToEmojiPicture from './pages/TextToEmojiPicture';
import TextReplicator from './pages/TextReplicator';
import CountDuplicates from './pages/CountDuplicates';
import Hearts1000 from './pages/Hearts1000';
import LovePercentage from './pages/LovePercentage';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen flex flex-col">
          <Header />
          <PrivacyBanner />
          <main className="flex-grow">
            <AdLayout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:id" element={<BlogPost />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/search" element={<Search />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/sitemap" element={<Sitemap />} />
                <Route path="/text-to-emoji" element={<TextToEmoji />} />
                <Route path="/text-to-emoji-picture" element={<TextToEmojiPicture />} />
                <Route path="/text-replicator" element={<TextReplicator />} />
                <Route path="/count-duplicates" element={<CountDuplicates />} />
                <Route path="/hearts-1000" element={<Hearts1000 />} />
                <Route path="/love-percentage" element={<LovePercentage />} />
                <Route path="/emoji/:id" element={<EmojiDetail />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </AdLayout>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;