import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar } from 'lucide-react';

const SeasonalEmojis = () => {
  const currentMonth = new Date().getMonth();
  
  // Adjust seasonal collections based on the current month
  const getSeasonalCollections = () => {
    // Winter (December - February)
    if (currentMonth === 11 || currentMonth === 0 || currentMonth === 1) {
      return [
        { path: "/christmas-1000", label: "Christmas Emojis 🎄", description: "Festive Christmas collection" },
        { path: "/santa-100", label: "Santa Emojis 🎅", description: "Santa Claus emoji collection" },
        { path: "/happy-christmas-50", label: "Happy Christmas × 50 🎄", description: "Christmas greetings" }
      ];
    }
    // Spring (March - May)
    else if (currentMonth >= 2 && currentMonth <= 4) {
      return [
        { path: "/roses-1000", label: "Spring Roses 🌹", description: "Beautiful spring flowers" },
        { path: "/rainbow-hearts-1000", label: "Rainbow Hearts 🌈", description: "Colorful spring hearts" },
        { path: "/love-letter-1000", label: "Love Letters 💌", description: "Spring romance collection" }
      ];
    }
    // Summer (June - August)
    else if (currentMonth >= 5 && currentMonth <= 7) {
      return [
        { path: "/party-1000", label: "Party Emojis 🎉", description: "Summer celebration emojis" },
        { path: "/food-drink", label: "Summer Treats 🍦", description: "Summer food & drinks" },
        { path: "/laugh-1000", label: "Fun Emojis 😄", description: "Summer fun expressions" }
      ];
    }
    // Fall (September - November)
    else {
      return [
        { path: "/colored-love-50", label: "Fall Colors 🍁", description: "Autumn-themed love messages" },
        { path: "/hearts-1000", label: "Cozy Hearts ❤️", description: "Warm heart collection" },
        { path: "/love-hearts-2000", label: "Fall Romance 💝", description: "Autumn love collection" }
      ];
    }
  };

  const seasonalCollections = getSeasonalCollections();

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold text-indigo-600 mb-6 flex items-center">
        <Calendar className="w-6 h-6 mr-2" />
        Seasonal Collections
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {seasonalCollections.map((collection, index) => (
          <Link
            key={index}
            to={collection.path}
            className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow transform hover:-translate-y-1 duration-300"
          >
            <h3 className="text-lg font-semibold text-gray-900 mb-2">{collection.label}</h3>
            <p className="text-sm text-gray-600">{collection.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SeasonalEmojis;