import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { emojis } from '../data/emojis';
import { Copy, Check, ArrowLeft, BookOpen } from 'lucide-react';
import RelatedPosts from '../components/RelatedPosts';
import { Helmet } from 'react-helmet-async';

const EmojiDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [copied, setCopied] = useState<string | null>(null);
  const [emoji, setEmoji] = useState(emojis.find(e => e.id === id));

  useEffect(() => {
    // Update emoji when id changes
    setEmoji(emojis.find(e => e.id === id));
  }, [id]);

  if (!emoji) {
    navigate('/');
    return null;
  }

  const handleCopyAll = () => {
    navigator.clipboard.writeText(emoji.emoji);
    setCopied('all');
    setTimeout(() => setCopied(null), 2000);
  };

  const handleCopyIndividual = (singleEmoji: string) => {
    navigator.clipboard.writeText(singleEmoji);
    setCopied(singleEmoji);
    setTimeout(() => setCopied(null), 2000);
  };

  const titleEmojis = Array.from(emoji.emoji.slice(0, 2)).join(' ');
  const isRepeatedEmoji = emoji.isRepeated || emoji.emoji.split('').every(char => char === emoji.emoji[0]);
  const uniqueEmojis = isRepeatedEmoji ? [] : Array.from(new Set(emoji.emoji));

  return (
    <>
      <Helmet>
        <title>{`${emoji.keyword} ${titleEmojis} Copy and Paste [${new Date().getFullYear()}]`}</title>
        <meta name="description" content={`【INSTANT COPY】 Get ${emoji.keyword}! ${titleEmojis} Perfect for ${emoji.description} ${titleEmojis} One-click copy & paste ${titleEmojis} Works everywhere! Updated for ${new Date().getFullYear()}`} />
        <link rel="canonical" href={`https://emojicollection.net/emoji/${emoji.id}`} />
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6 transition-colors"
          >
            <ArrowLeft className="mr-2" size={20} />
            Back to Home
          </button>

          <article className="bg-white rounded-2xl shadow-lg p-8">
            <header className="text-center mb-8">
              <h1 className="text-3xl font-bold text-gray-900 mb-2">
                {titleEmojis} {emoji.keyword} {titleEmojis}
              </h1>
              <p className="text-lg text-gray-600 mb-4">
                {isRepeatedEmoji 
                  ? 'Click the button below to copy all emojis at once'
                  : 'Click any emoji to copy it individually, or use the copy all button'}
              </p>
              <p className="text-xl text-gray-600 mb-8">
                {emoji.description}
              </p>

              <div className="max-w-2xl mx-auto">
                {isRepeatedEmoji ? (
                  <div className="bg-gray-50 rounded-lg p-6">
                    <div className="text-4xl mb-6 break-words">
                      {emoji.preview || emoji.emoji.slice(0, 50)}
                      {emoji.emoji.length > 50 && '...'}
                    </div>
                    <button
                      onClick={handleCopyAll}
                      className={`inline-flex items-center px-6 py-3 rounded-lg text-white transition-colors ${
                        copied === 'all' ? 'bg-green-500' : 'bg-[#ff0000] hover:bg-[#ff0000]/90'
                      }`}
                    >
                      {copied === 'all' ? (
                        <>
                          <Check className="mr-2" size={20} />
                          Copied All!
                        </>
                      ) : (
                        <>
                          <Copy className="mr-2" size={20} />
                          Copy All {emoji.keyword}
                        </>
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="space-y-6">
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                      {uniqueEmojis.map((singleEmoji, index) => (
                        <button
                          key={index}
                          onClick={() => handleCopyIndividual(singleEmoji)}
                          className={`relative group p-4 text-3xl bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors ${
                            copied === singleEmoji ? 'ring-2 ring-green-500' : ''
                          }`}
                        >
                          <span className="block mb-2">{singleEmoji}</span>
                          <span className={`absolute inset-0 flex items-center justify-center bg-black/50 text-white text-sm font-medium rounded-lg opacity-0 group-hover:opacity-100 transition-opacity ${
                            copied === singleEmoji ? 'opacity-100 bg-green-500/50' : ''
                          }`}>
                            {copied === singleEmoji ? (
                              <>
                                <Check className="mr-1" size={16} />
                                Copied!
                              </>
                            ) : (
                              <>
                                <Copy className="mr-1" size={16} />
                                Copy
                              </>
                            )}
                          </span>
                        </button>
                      ))}
                    </div>
                    
                    <div className="text-center">
                      <button
                        onClick={handleCopyAll}
                        className={`inline-flex items-center px-6 py-3 rounded-lg text-white transition-colors ${
                          copied === 'all' ? 'bg-green-500' : 'bg-[#ff0000] hover:bg-[#ff0000]/90'
                        }`}
                      >
                        {copied === 'all' ? (
                          <>
                            <Check className="mr-2" size={20} />
                            Copied All!
                          </>
                        ) : (
                          <>
                            <Copy className="mr-2" size={20} />
                            Copy All Emojis
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </header>
            
            <div className="border-t border-gray-200 pt-8">
              <h2 className="text-2xl font-semibold mb-4">Usage & Context</h2>
              <p className="text-gray-600">
                Perfect for expressing {emoji.keyword.toLowerCase()} in digital communication.
                This emoji set is commonly used in {emoji.category}-related contexts and can help
                convey complex emotions or ideas in a simple, visual way. 
                {isRepeatedEmoji 
                  ? ' Simply click the copy button above to copy all emojis at once!'
                  : ' Click individual emojis to copy them one by one, or use the copy all button to get the complete set!'}
              </p>
            </div>

            {/* Related Posts */}
            <RelatedPosts currentId={emoji.id} category={emoji.category} />
          </article>
        </div>
      </div>
    </>
  );
};

export default EmojiDetail;