import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { sitemapSections } from '../data/sitemapSections';
import SitemapSection from '../components/SitemapSection';

const Sitemap = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          onClick={() => navigate('/')}
          className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6 transition-colors"
        >
          <ArrowLeft className="mr-2" size={20} />
          Back to Home
        </button>

        <div className="bg-white rounded-2xl shadow-lg p-8">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              Sitemap
            </h1>
            <p className="text-lg text-gray-600">
              Explore all pages and tools available on Emoji Collection
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {sitemapSections.map((section, index) => (
              <SitemapSection key={index} section={section} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sitemap;