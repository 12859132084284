import React from 'react';
import { Link } from 'react-router-dom';
import { Heart, Star, Smile, Gift, Music, Flag } from 'lucide-react';

const QuickLinks = () => {
  const links = [
    {
      title: "Love & Hearts",
      icon: Heart,
      items: [
        { path: "/hearts-1000", label: "1000 Hearts" },
        { path: "/i-love-you-10000", label: "I Love You × 10000" },
        { path: "/love-emojis", label: "Love Emoji Collection" }
      ]
    },
    {
      title: "Popular Collections",
      icon: Star,
      items: [
        { path: "/roses-1000", label: "1000 Roses" },
        { path: "/kiss-1000", label: "1000 Kisses" },
        { path: "/party-1000", label: "1000 Party Emojis" }
      ]
    },
    {
      title: "Expressions",
      icon: Smile,
      items: [
        { path: "/laugh-1000", label: "1000 Laugh Emojis" },
        { path: "/sad-1000", label: "1000 Sad Emojis" },
        { path: "/sorry-1000", label: "1000 Sorry Messages" }
      ]
    },
    {
      title: "Seasonal",
      icon: Gift,
      items: [
        { path: "/christmas-1000", label: "1000 Christmas Emojis" },
        { path: "/santa-100", label: "100 Santa Emojis" },
        { path: "/happy-christmas-50", label: "Happy Christmas × 50" }
      ]
    },
    {
      title: "Entertainment",
      icon: Music,
      items: [
        { path: "/pop-culture", label: "Pop Culture Emojis" },
        { path: "/food-drink", label: "Food & Drink Emojis" },
        { path: "/flags-symbols", label: "Flags & Symbols" }
      ]
    }
  ];

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold text-indigo-600 mb-6">Quick Access</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
        {links.map((section, index) => (
          <div key={index} className="bg-white rounded-xl shadow-md p-6">
            <div className="flex items-center mb-4">
              <section.icon className="w-5 h-5 text-indigo-600 mr-2" />
              <h3 className="font-semibold text-gray-900">{section.title}</h3>
            </div>
            <ul className="space-y-2">
              {section.items.map((item, itemIndex) => (
                <li key={itemIndex}>
                  <Link
                    to={item.path}
                    className="text-gray-600 hover:text-indigo-600 transition-colors text-sm block py-1"
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuickLinks;