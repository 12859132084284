import React, { useState } from 'react';
import { ArrowLeft, Heart, Copy, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Hearts1000 = () => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const hearts = [
    '❤️', '💖', '💝', '💕', '💗', '💓', '💘', '💞', '💟', '♥️'
  ];

  const heartMessage = Array(1000)
    .fill(null)
    .map(() => hearts[Math.floor(Math.random() * hearts.length)])
    .join('');

  const handleCopy = () => {
    navigator.clipboard.writeText(heartMessage);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <Helmet>
        <title>1000 Hearts Copy and Paste ❤️ Free Heart Emojis [2024] 💝</title>
        <meta name="description" content="【INSTANT COPY】 Get 1000 heart emojis! ❤️ Perfect mix of love hearts 💝 One-click copy & paste 💖 Works everywhere! Updated for 2024 ♥️ Express your love with our curated heart collection!" />
        <link rel="canonical" href="https://emojicollection.net/hearts-1000" />
        <meta property="og:title" content="1000 Hearts Copy and Paste ❤️ Free Heart Emojis [2024] 💝" />
        <meta property="og:description" content="【INSTANT COPY】 Get 1000 heart emojis! ❤️ Perfect mix of love hearts 💝 One-click copy & paste 💖 Works everywhere! Updated for 2024" />
        <meta property="og:url" content="https://emojicollection.net/hearts-1000" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="1000 Hearts Copy and Paste ❤️ Free Heart Emojis [2024] 💝" />
        <meta name="twitter:description" content="【INSTANT COPY】 Get 1000 heart emojis! ❤️ Perfect mix of love hearts 💝 One-click copy & paste 💖 Works everywhere! Updated for 2024" />
        <meta name="keywords" content="1000 hearts, heart emoji copy paste, heart emojis, love emoji, heart symbols, instagram hearts, whatsapp hearts, facebook hearts" />
      </Helmet>

      {/* Rest of the component remains unchanged */}
    </>
  );
};

export default Hearts1000;