import React from 'react';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'lucide-react';
import type { SitemapSection as SitemapSectionType } from '../types/sitemap';

interface SitemapSectionProps {
  section: SitemapSectionType;
}

const SitemapSection: React.FC<SitemapSectionProps> = ({ section }) => {
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-[#ff0000] border-b border-gray-200 pb-2">
        {section.title}
      </h2>
      <div className="space-y-4">
        {section.links.map((link, index) => (
          <Link
            key={index}
            to={link.path}
            className="group block p-4 rounded-lg hover:bg-gray-50 transition-colors"
          >
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-lg font-medium text-gray-900 group-hover:text-[#ff0000] transition-colors">
                  {link.label}
                </h3>
                {link.description && (
                  <p className="mt-1 text-sm text-gray-600">
                    {link.description}
                  </p>
                )}
              </div>
              <ExternalLink 
                className="w-5 h-5 text-gray-400 group-hover:text-[#ff0000] transition-colors" 
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SitemapSection;