import React from 'react';
import { Link } from 'react-router-dom';
import { TrendingUp } from 'lucide-react';

const TrendingCollections = () => {
  const collections = [
    {
      title: "Most Popular",
      items: [
        { path: "/hearts-1000", label: "1000 Hearts ❤️", description: "Most loved heart collection" },
        { path: "/roses-1000", label: "1000 Roses 🌹", description: "Beautiful rose emojis" },
        { path: "/i-love-you-10000", label: "I Love You × 10000 💝", description: "Express infinite love" }
      ]
    },
    {
      title: "Rising Stars",
      items: [
        { path: "/rainbow-hearts-1000", label: "1000 Rainbow Hearts 🌈", description: "Colorful heart collection" },
        { path: "/blue-roses-1000", label: "1000 Blue Roses 💠", description: "Unique blue roses" },
        { path: "/love-hearts-2000", label: "2000 Love Hearts 💖", description: "Extra large heart collection" }
      ]
    }
  ];

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold text-indigo-600 mb-6 flex items-center">
        <TrendingUp className="w-6 h-6 mr-2" />
        Trending Collections
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {collections.map((section, index) => (
          <div key={index} className="bg-white rounded-xl shadow-md p-6">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">{section.title}</h3>
            <div className="space-y-4">
              {section.items.map((item, itemIndex) => (
                <Link
                  key={itemIndex}
                  to={item.path}
                  className="block p-4 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h4 className="font-medium text-gray-900">{item.label}</h4>
                      <p className="text-sm text-gray-600">{item.description}</p>
                    </div>
                    <span className="text-indigo-600">→</span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingCollections;